<template>
  <v-row class="provider-new">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/providers" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>New provider
          </router-link>
        </div>
      </div>
      <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <div class="text-uppercase text-h5 font-weight-bold">Provider details</div>
        <div class="px-sm-10 py-sm-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                :error-messages="nameErrors"
                label="Name"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
              ></v-text-field>
              <v-text-field
                v-model="login"
                :error-messages="loginErrors"
                label="Login"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :error-messages="passwordErrors"
                label="Password"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
              <v-text-field
                v-model="host"
                :error-messages="hostErrors"
                label="Host/IP"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
              >
              </v-text-field>
              <v-text-field
                v-model="port"
                :error-messages="portErrors"
                label="Port"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-16">
              <v-checkbox v-model="enabled" color="success" class="mt-0">
                <template v-slot:label>
                  <div class="d-flex flex-column">
                    <div class="font-weight-medium">Enabled</div>
                    <div class="f14 opasity--text">In account enabled, the gateway will bind to Provider</div>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="tls" color="success" class="mt-2">
                <template v-slot:label>
                  <div class="d-flex flex-column">
                    <div class="font-weight-medium">TLS connection</div>
                    <div class="f14 opasity--text">Connect to Provider using TLS</div>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="tlsValidation" color="success" class="mt-2">
                <template v-slot:label>
                  <div class="d-flex flex-column">
                    <div class="font-weight-medium">Validate TLS certificate</div>
                    <div class="f14 opasity--text">Perform Provider TLS certificate validation. Disable in case Provider</div>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-divider class="my-10"></v-divider>
          <div class="text-sm-right text-center">
            <v-btn elevation="0" width="184" height="48" color="primary" @click="create">Save</v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    name: { required },
    login: { required },
    password: { required },
    host: { required },
    port: { required },
  },
  data() {
    return {
      show: false,
      name: '',
      login: '',
      password: '',
      host: '',
      port: '',
      enabled: false,
      tls: false,
      tlsValidation: false,
      error: null,
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      !this.$v.name.required && errors.push('Please enter provider name');
      this.error == 'name__invalid' && errors.push('Provided name is not valid');
      this.error == 'name__already_exists' && errors.push('Provided name is already exist. Please use another one');
      return errors;
    },
    loginErrors() {
      const errors = [];
      if (!this.$v.login.$dirty) {
        return errors;
      }
      !this.$v.login.required && errors.push('Please enter provider login');
      this.error == 'login__invalid' && errors.push('Provided login is not valid');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      !this.$v.password.required && errors.push('Please enter provider password');
      this.error == 'password__invalid' && errors.push('Provided password is not valid');
      return errors;
    },
    hostErrors() {
      const errors = [];
      if (!this.$v.host.$dirty) {
        return errors;
      }
      !this.$v.host.required && errors.push('Please enter provider host');
      this.error == 'host__invalid' && errors.push('Provided host is not valid');
      return errors;
    },
    portErrors() {
      const errors = [];
      if (!this.$v.port.$dirty) {
        return errors;
      }
      !this.$v.port.required && errors.push('Please enter provider port');
      this.error == 'port__invalid' && errors.push('Provided port is not valid');
      return errors;
    },
  },
  methods: {
    notifi(btn) {
      if (btn == 'create') {
        this.$notify({ message: 'Provider has been created', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'error') {
        this.$notify({ message: 'Error', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async create() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.error = null;
        const data = {
          name: this.name,
          login: this.login,
          password: this.password,
          enabled: this.enabled,
          host: this.host,
          port: +this.port,
          tls: this.tls,
          tls_validation: this.tlsValidation,
        };
        await this.$store
          .dispatch('createProvider', data)
          .then((res) => {
            this.notifi('create');
            this.$router.push(`/providers/${res.data.id}`);
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            this.notifi();
          });
      }
    },
  },
};
</script>
